import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Spacer,
    SkeletonText,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useBreakpointValue,
    VStack,
    SlideFade,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
    Input,
    Tag,
    TagCloseButton,
    TagLabel,
    Spinner,
    toast,
    useToast,
} from "@chakra-ui/react";
import { BsArrowLeftCircle, BsArchive, BsCalendar2Week, BsCheckCircle, BsExclamationCircle, BsHandThumbsUp, BsTrash, BsPencil, BsPeople, BsPlusCircle, BsPaperclip } from "react-icons/bs";

import { acknowledgeRiskAssessment, beginNewReview, getAttachment, getRiskAssessment, patchRiskAssessment, getVersion, postEditingTeam, updateRiskAssessmentTeams } from "../../features/riskAssessments/riskAssessments.actions";
import { localDate } from "../../helpers/DayJsHelper";

import { Acknowledgements } from "./Acknowledgements";
import { Versions } from "./Versions";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";
import { HazardReader } from "./HazardReader";
import { isEmpty, isNull } from "lodash";

import miniLogoWhite from "../../assets/logos/obbi_logo_mini_white.png";
import hazardImg from "../../assets/images/incident.svg";

import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from "lodash";

import { getCurrentTeams } from "../../features/common/common.actions";
import { TeamMessageCombobox } from "./TeamMessageCombobox";
import { EditingTeamCombobox } from "./EditingTeamCombobox";
import { CheckProductTierFlag } from "../../helpers/Utilities";

dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const RiskAssessment = () => {
    const navigateTo = useNavigate();
    const { state }: any = useLocation();
    const toast = useToast();

    const [tabIndex, setTabIndex] = useState(0);
    const [firstTimeLoading, setFirstTimeLoading] = useState(true);
    const [loading, setLoading] = useState<boolean>(true);

    const [riskAssessment, setRiskAssessment] = useState<any>({
        raid: null,
        raorganisation: null,
        raname: null,
        radescription: null,
        rastate: null,
        rapath: null,
        racategory: null,
        radatecreated: null,
        racreatedby: null,
        radatelastmodified: null,
        ralastmodifiedby: null,
        raexpirydate: null,
        raalertbeforeexpirynumber: null,
        raalertbeforeexpiryunit: null,
        raalertwhenexpired: null,
        raexpirystate: null,
        racurrentversion: null,
        raeditingteam: null,
        raonlymanagerscanedit: null,
        rapublishedversion: null,
        creatorforename: null,
        creatorsurname: null,
        modforename: null,
        modsurname: null,
        editingteamname: null,
        alertbeforeexpiry: null,
        alertwhenexpired: null,
        expired: null,
        expiring: null,
        currentversion: {
            ravid: null,
            ravriskassessment: null,
            ravorganisation: null,
            ravsequencenumber: null,
            ravstate: null,
            ravname: null,
            ravreviewdate: null,
            ravcarriedoutby: null,
            ravcarriedouttext: null,
            ravreviewnotes: null,
            ravnextreviewdate: null,
            ravpath: null,
            ravdatecreated: null,
            ravcreatedby: null,
            ravdatelastmodified: null,
            ravlastmodifiedby: null,
            creatorforename: null,
            creatorsurname: null,
            modforename: null,
            modsurname: null,
            hazards: []
        },
        publishedversion: {
            ravid: null,
            ravriskassessment: null,
            ravorganisation: null,
            ravsequencenumber: null,
            ravstate: null,
            ravname: null,
            ravreviewdate: null,
            ravcarriedoutby: null,
            ravcarriedouttext: null,
            ravreviewnotes: null,
            ravnextreviewdate: null,
            ravpath: null,
            ravdatecreated: null,
            ravcreatedby: null,
            ravdatelastmodified: null,
            ravlastmodifiedby: null,
            creatorforename: null,
            creatorsurname: null,
            modforename: null,
            modsurname: null,
            hazards: []
        },
        teams: []
    });

    const [version, setVersion] = useState<any>({
        ravid: null,
        ravriskassessment: null,
        ravorganisation: null,
        ravsequencenumber: null,
        ravstate: null,
        ravname: null,
        ravreviewdate: null,
        ravcarriedoutby: null,
        ravcarriedouttext: null,
        ravreviewnotes: null,
        ravnextreviewdate: null,
        ravpath: null,
        ravdatecreated: null,
        ravcreatedby: null,
        ravdatelastmodified: null,
        ravlastmodifiedby: null,
        creatorforename: null,
        creatorsurname: null,
        modforename: null,
        modsurname: null,
        hazards: []
    });

    const [editBody, setEditBody] = useState<any>({
        editingTeam: 0,
        name: "",
        description: "",
        category: "",
        teams: [],
        nextReviewDate: ""
    });

    const [isDraftVersion, setIsDraftVersion] = useState(false);
    const [publishedVersionId, setPublishedVersionId] = useState(null);

    const [onEditingTeam, setOnEditingTeam] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const [editingTeams, setEditingTeams] = useState<any>([]);
    const [editingTeam, setEditingTeam] = useState<any>({
        teamid: "",
        teamname: ""
    });

    const [acknowledgementTeams, setAcknowledgementTeams] = useState<any>([]);
    const [selectedAcknowledgementTeams, setSelectedAcknowledgementTeams] = useState<any>([]);
    const [acknowledgementTeamsToUpdate, setAcknowledgementTeamsToUpdate] = useState<any>([]);

    const [acknowledgement, setAcknowledgement] = useState<any>(null);

    const [date, setDate] = useState<any>("");

    const user = useSelector((state: RootState) => state.authReducer.user);
    const isManager = user?.isTeamManager;

    const isMobile = useBreakpointValue({ base: true, md: false });

    const hasRiskAssessmentEditingPermission = CheckProductTierFlag(user?.producttier?.ptriskassessmenteditingflag);

    const [isEditManagingTeamModalOpen, setIsEditManagingTeamModalOpen] = useState(false);
    const [canEditManagingTeam, setCanEditManagingTeam] = useState(false);

    const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
    const [canEditName, setCanEditName] = useState(false);

    const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);
    const [canEditCategory, setCanEditCategory] = useState(false);

    const [isEditReviewDateModalOpen, setIsEditReviewDateModalOpen] = useState(false);
    const [canEditReviewDate, setCanEditReviewDate] = useState(false);

    const [isEditTeamsModalOpen, setIsEditTeamsModalOpen] = useState(false);
    const [canEditTeams, setCanEditTeams] = useState(false);

    const [isEditDescriptionModalOpen, setIsEditDescriptionModalOpen] = useState(false);
    const [canEditDescription, setCanEditDescription] = useState(false);

    const [isAcknowledgeModalOpen, setIsAcknowledgeModalOpen] = useState(false);

    const [attachments, setAttachments] = useState<any[]>([]);
    const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
    const [pdfIsOpen, setPdfIsOpen] = useState(false);

    const [currentImage, setCurrentImage] = useState("")
    const [currentCert, setCurrentCert] = useState({
        id: 0,
        isPdf: false,
        uri: "",
        fileName: ""
    });

    // PDF pages
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const resetDocViewer = () => {
        setNumPages(0);
        setPageNumber(1);
    };

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
    };

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc =
            `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    const getRiskAssesssmentFunc = () => {
        setLoading(true);

        getVersion(state.raid, state.ravid)
            .then((res: any) => {
                setVersion(res.version);
                setIsDraftVersion(res?.version?.ravstate === '0');

                setOnEditingTeam(res.userCanEditRA)
                setCanEdit(res.userCanEditRA && (res?.version?.ravstate === '0' || res?.version?.ravstate === '1'));

                const attachmentThumbnails: any[] = [];

                for (let attachment of res?.version?.attachments) {
                    const thumbnail = `${process.env.REACT_APP_OBBI_API_URL}/riskAssessments/attachment?id=${res?.version?.ravriskassessment}&version=${res?.version?.ravid}&attachment=${attachment?.raapath}&datauri=false&thumbnail=true`;

                    attachmentThumbnails.push({
                        "thumbnail": thumbnail,
                        "fileName": attachment.raafilename,
                        "path": attachment.raapath,
                        "isPdf": attachment.ispdf,
                        "isDoc": attachment.isdoc,
                    });
                }

                setAttachments(attachmentThumbnails);

                getRiskAssessment(res?.raId)
                    .then((getRiskAssessmentResponse: any) => {
                        setRiskAssessment(getRiskAssessmentResponse.riskAssessment);
                        setPublishedVersionId(getRiskAssessmentResponse.riskAssessment?.publishedversion?.ravid);
                        setAcknowledgement(getRiskAssessmentResponse.riskAssessment?.acknowledgement);
                        setSelectedAcknowledgementTeams(getRiskAssessmentResponse.riskAssessment?.teams);
                        setEditingTeam({
                            teamid: getRiskAssessmentResponse?.riskAssessment?.raeditingteam,
                            teamname: getRiskAssessmentResponse?.riskAssessment?.editingteamname
                        });

                        const reviewDate = dayjs(getRiskAssessmentResponse.riskAssessment?.raexpirydate)?.format("YYYY-MM-DD");
                        setDate(reviewDate);
                    })
                    .catch((error: any) => {
                        console.log(error);
                    });
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                setFirstTimeLoading(false);
            });
    };

    useEffect(() => {
        if (state?.resetTabs) setTabIndex(0);

        getRiskAssesssmentFunc();
        getEditingTeams();
        getTeamsToAcknowledge();
    }, [state]);

    async function createNewReview() {
        try {
            await beginNewReview(riskAssessment?.raid)
                .then((res: any) => {
                    navigateTo(`/riskassessments/edit/${res.riskAssessment?.raid}`,
                        {
                            state: {
                                isReview: true,
                                step: 1
                            }
                        }
                    );
                });
        } catch (error) {
            console.log(error);
        }
    }

    async function acknowledge() {
        try {
            await acknowledgeRiskAssessment(riskAssessment?.raid)
                .then(() => {
                    getRiskAssesssmentFunc();

                    toast({
                        title: "Acknowledged",
                        description: "",
                        status: "success",
                        duration: 5000,
                        isClosable: true
                    })
                });
        } catch (error) {
            console.log(error);
        } finally {
            setIsAcknowledgeModalOpen(false);
        }
    }

    const patchRiskAssessmentFromData = (name: string, value: any) => {
        const patchBody = {
            id: riskAssessment?.raid,
            name: name,
            value: value
        };

        patchRiskAssessment(patchBody)
            .then((res: any) => {
                setRiskAssessment(res.riskAssessment);
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => { });
    };

    const postRiskAssessmentTeams = () => {
        const postBody = {
            id: riskAssessment?.raid,
            teams: editBody.teams || riskAssessment?.teams
        };

        updateRiskAssessmentTeams(postBody)
            .then((res: any) => {
                setRiskAssessment(res.riskAssessment);
                setSelectedAcknowledgementTeams(res.riskAssessment?.teams);
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => { });
    };

    const getTeamsToAcknowledge = () => {
        getCurrentTeams()
            .then((res: any) => {
                let updatedTeams = res.manages;

                for (const team of selectedAcknowledgementTeams) {
                    updatedTeams = _.filter(
                        updatedTeams,
                        (t: any) => {
                            return t?.teamid !== team?.teamid
                        }
                    );
                }

                setAcknowledgementTeams(updatedTeams);
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const selectTeam = (arg: any): void => {
        if (arg.teamid === "0") return;

        let currentSelection = [...selectedAcknowledgementTeams];
        currentSelection.push(arg);

        const ackTeams = [...acknowledgementTeams];

        let updatedTeams = _.filter(
            ackTeams,
            (team: any) => {
                return arg.teamid !== team.teamid;
            }
        );
        updatedTeams = _.orderBy(updatedTeams, [team => team?.teamname?.toLowerCase()], ['asc']);

        setAcknowledgementTeams(updatedTeams);
        setSelectedAcknowledgementTeams(currentSelection);

        setEditBody({
            ...editBody,
            teams: currentSelection.map((t: any) => { return t.teamid })
        });
    };

    const deselectTeam = (id: string): void => {
        let currentSelection = [...selectedAcknowledgementTeams];

        const deselectedTeam =
            _.filter(
                currentSelection,
                (team: any) => {
                    return id === team.teamid;
                }
            );

        currentSelection = _.filter(
            currentSelection,
            (team: any) => {
                return !deselectedTeam.includes(team)
            }
        );

        let updatedTeams = [...acknowledgementTeams];
        updatedTeams = updatedTeams.concat(deselectedTeam);
        updatedTeams = _.orderBy(updatedTeams, [team => team?.teamname?.toLowerCase()], ['asc']);

        setAcknowledgementTeams(updatedTeams);
        setSelectedAcknowledgementTeams(currentSelection);

        setEditBody({
            ...editBody,
            teams: currentSelection.map((t: any) => { return t.teamid })
        });
    };

    const getEditingTeams = () => {
        getCurrentTeams()
            .then((res: any) => {
                setEditingTeams(res.manages);
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const selectEditingTeam = (e: any) => {
        setEditingTeam({
            teamid: e.teamid,
            teamname: e.teamname
        });
    };

    const fetchAttachment = async (
        id: string,
        version: string,
        attachment: string,
        isPdf: boolean,
        isDoc: boolean
    ) => {
        const pdf = isPdf || isDoc;

        await getAttachment(id, version, attachment, pdf)
            .then((res: any) => {
                if (pdf) {
                    setCurrentCert({
                        id: Number(id),
                        isPdf: isPdf,
                        fileName: res.attachment.aafilename,
                        uri: res.datauri
                    });
                    setPdfIsOpen(true);
                } else {
                    setCurrentImage(res.datauri);
                    setIsAttachmentOpen(true);
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const renderEditManagingTeamModal = () => {
        return <Modal
            size="xl"
            isOpen={isEditManagingTeamModalOpen}
            onClose={() => {
                setIsEditManagingTeamModalOpen(false);
                setCanEditManagingTeam(false);
            }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader fontSize={canEditManagingTeam ? "xl" : "lg"}>
                    {canEditManagingTeam ? "Managing Team" : "This Risk Assessment has already been published"}
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody pb={6}>
                    {
                        canEditManagingTeam ?
                            <FormControl>
                                <Text
                                    fontWeight={500}
                                    fontSize="sm"
                                    color="gray.500"
                                    mb={2}
                                >
                                    Members of this team are able to make changes to this Risk Assessment prior to publishing.
                                </Text>

                                <EditingTeamCombobox
                                    teams={editingTeams}
                                    riskAssessmentData={riskAssessment}
                                    editingTeam={editingTeam}
                                    update={selectEditingTeam}
                                />
                            </FormControl> :

                            <Box w="100%">
                                <HStack w="100%" alignItems="start" gap={1}>
                                    <Image src={hazardImg} h="85px" />

                                    <Spacer />

                                    <VStack gap={3} alignItems="start">
                                        <Box textAlign="left" w="100%">
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                There may be acknowledgments associated with it.
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                Are you sure you want to make changes?
                                            </Text>

                                            <Button
                                                mt={3}
                                                w="100%"
                                                size="sm"
                                                variant="outline"
                                                color="gray.600"
                                                fontWeight={600}
                                                onClick={() => {
                                                    setCanEditManagingTeam(true);
                                                }}
                                            >
                                                <HStack>
                                                    <Icon as={BsPencil} />
                                                    <Text>Continue Editing</Text>
                                                </HStack>
                                            </Button>
                                        </Box>
                                    </VStack>
                                </HStack>
                            </Box>
                    }
                </ModalBody>

                {
                    canEditManagingTeam && <ModalFooter>
                        <HStack>
                            <Button
                                cursor="pointer"
                                size="sm"
                                onClick={() => {
                                    setIsEditManagingTeamModalOpen(false);
                                    setCanEditManagingTeam(false);
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                colorScheme="brand"
                                size="sm"
                                _active={{ bg: "" }}
                                _hover={{ bg: "#248451" }}
                                onClick={() => {
                                    postEditingTeam({
                                        id: riskAssessment?.raid,
                                        editingTeam: Number(editingTeam.teamid)
                                    })
                                        .then((res: any) => {
                                            setRiskAssessment(res?.riskAssessment);
                                        })
                                        .catch((error: any) => {
                                            console.log(error);
                                        })
                                        .finally(() => {
                                            setCanEditManagingTeam(false);
                                            setIsEditManagingTeamModalOpen(false);
                                        });
                                }}
                            >
                                Update
                            </Button>
                        </HStack>
                    </ModalFooter>
                }
            </ModalContent>
        </Modal>
    };

    const renderEditNameModal = () => {
        return <Modal
            size="xl"
            isOpen={isEditNameModalOpen}
            onClose={() => {
                setIsEditNameModalOpen(false);
                setCanEditName(false);
            }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader fontSize={canEditName ? "xl" : "lg"}>
                    {canEditName ? "Risk Assessment Name" : "This Risk Assessment has already been published"}
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody pb={6}>
                    {
                        canEditName ?
                            <FormControl>
                                <Input
                                    defaultValue={riskAssessment?.raname}
                                    onChange={(e) => {
                                        setEditBody({
                                            ...editBody,
                                            title: e.target.value
                                        });
                                    }}
                                />
                            </FormControl> :

                            <Box>
                                <HStack w="100%" alignItems="start" gap={1}>
                                    <Image src={hazardImg} h="85px" />

                                    <Spacer />

                                    <VStack gap={3} alignItems="start">
                                        <Box textAlign="left" w="100%">
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                There may be acknowledgments associated with it.
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                Are you sure you want to make changes?
                                            </Text>

                                            <Button
                                                mt={3}
                                                w="100%"
                                                size="sm"
                                                variant="outline"
                                                color="gray.600"
                                                fontWeight={600}
                                                onClick={() => {
                                                    setCanEditName(true);
                                                }}
                                            >
                                                <HStack>
                                                    <Icon as={BsPencil} />
                                                    <Text>Continue Editing</Text>
                                                </HStack>
                                            </Button>
                                        </Box>
                                    </VStack>
                                </HStack>
                            </Box>
                    }
                </ModalBody>

                {
                    canEditName && <ModalFooter>
                        <HStack>
                            <Button
                                cursor="pointer"
                                size="sm"
                                onClick={() => {
                                    setIsEditNameModalOpen(false);
                                    setCanEditName(false);
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                className="hover-pop"
                                colorScheme="brand"
                                size="sm"
                                _active={{ bg: "" }}
                                _hover={{ bg: "#248451" }}
                                onClick={() => {
                                    patchRiskAssessmentFromData("name", editBody.name || riskAssessment?.raname);
                                    setIsEditNameModalOpen(false);
                                    setCanEditName(false);
                                }}
                            >
                                Update
                            </Button>
                        </HStack>
                    </ModalFooter>
                }
            </ModalContent>
        </Modal>
    };

    const renderEditCategoryModal = () => {
        return <Modal
            size="xl"
            isOpen={isEditCategoryModalOpen}
            onClose={() => {
                setIsEditCategoryModalOpen(false);
                setCanEditCategory(false);
            }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader fontSize={canEditCategory ? "xl" : "lg"}>
                    {canEditCategory ? "Risk Assessment Category" : "This Risk Assessment has already been published"}
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody pb={6}>
                    {
                        canEditCategory ?
                            <FormControl>
                                <Input
                                    defaultValue={riskAssessment?.racategory || ""}
                                    onChange={(e) => {
                                        setEditBody({
                                            ...editBody,
                                            category: e.target.value
                                        });
                                    }}
                                />
                            </FormControl> :

                            <Box rounded="lg">
                                <HStack w="100%" alignItems="start" gap={1}>
                                    <Image src={hazardImg} h="85px" />

                                    <Spacer />

                                    <VStack gap={3} alignItems="start">
                                        <Box textAlign="left" w="100%">
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                There may be acknowledgments associated with it.
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                Are you sure you want to make changes?
                                            </Text>

                                            <Button
                                                mt={3}
                                                w="100%"
                                                size="sm"
                                                variant="outline"
                                                color="gray.600"
                                                fontWeight={600}
                                                onClick={() => {
                                                    setCanEditCategory(true);
                                                }}
                                            >
                                                <HStack>
                                                    <Icon as={BsPencil} />
                                                    <Text>Continue Editing</Text>
                                                </HStack>
                                            </Button>
                                        </Box>
                                    </VStack>
                                </HStack>
                            </Box>
                    }
                </ModalBody>

                {
                    canEditCategory && <ModalFooter>
                        <HStack>
                            <Button
                                cursor="pointer"
                                size="sm"
                                onClick={() => {
                                    setIsEditCategoryModalOpen(false);
                                    setCanEditCategory(false);
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                colorScheme="brand"
                                size="sm"
                                _active={{ bg: "" }}
                                _hover={{ bg: "#248451" }}
                                onClick={() => {
                                    patchRiskAssessmentFromData("category", editBody.category || riskAssessment?.racategory);
                                    setIsEditCategoryModalOpen(false);
                                    setCanEditCategory(false);
                                }}
                            >
                                Update
                            </Button>
                        </HStack>
                    </ModalFooter>
                }
            </ModalContent>
        </Modal>
    };

    const renderEditDescriptionModal = () => {
        return <Modal
            size="xl"
            isOpen={isEditDescriptionModalOpen}
            onClose={() => {
                setIsEditDescriptionModalOpen(false);
                setCanEditDescription(false);
            }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader fontSize={canEditDescription ? "xl" : "lg"}>
                    {canEditDescription ? "Risk Assessment Description" : " This Risk Assessment has already been published"}
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody pb={6}>
                    {
                        canEditDescription ?
                            <FormControl>
                                <Textarea
                                    rows={5}
                                    defaultValue={riskAssessment?.radescription || ""}
                                    onChange={(e: any) => {
                                        setEditBody({
                                            ...editBody,
                                            description: e.target.value
                                        });
                                    }}
                                />
                            </FormControl> :

                            <Box rounded="lg">
                                <HStack w="100%" alignItems="start" gap={1}>
                                    <Image src={hazardImg} h="85px" />

                                    <Spacer />

                                    <VStack gap={3} alignItems="start">
                                        <Box textAlign="left" w="100%">
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                There may be acknowledgments associated with it.
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                Are you sure you want to make changes?
                                            </Text>

                                            <Button
                                                mt={3}
                                                w="100%"
                                                size="sm"
                                                variant="outline"
                                                color="gray.600"
                                                fontWeight={600}
                                                onClick={() => {
                                                    setCanEditDescription(true);
                                                }}
                                            >
                                                <HStack>
                                                    <Icon as={BsPencil} />
                                                    <Text>Continue Editing</Text>
                                                </HStack>
                                            </Button>
                                        </Box>
                                    </VStack>
                                </HStack>
                            </Box>
                    }
                </ModalBody>

                {
                    canEditDescription && <ModalFooter>
                        <HStack>
                            <Button
                                cursor="pointer"
                                size="sm"
                                onClick={() => {
                                    setIsEditDescriptionModalOpen(false);
                                    setCanEditDescription(false);
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                colorScheme="brand"
                                size="sm"
                                _active={{ bg: "" }}
                                _hover={{ bg: "#248451" }}
                                onClick={() => {
                                    patchRiskAssessmentFromData("description", editBody.description || riskAssessment?.radescription);
                                    setIsEditDescriptionModalOpen(false);
                                    setCanEditDescription(false);
                                }}
                            >
                                Update
                            </Button>
                        </HStack>
                    </ModalFooter>
                }
            </ModalContent>
        </Modal>
    };

    const renderEditReviewDateModal = () => {
        return <Modal
            size="xl"
            isOpen={isEditReviewDateModalOpen}
            onClose={() => {
                setIsEditReviewDateModalOpen(false);
                setCanEditReviewDate(false);
            }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader fontSize={canEditReviewDate ? "xl" : "lg"}>
                    {canEditReviewDate ? "Next Review Date" : "This Risk Assessment has already been published"}
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody pb={6}>
                    {
                        canEditReviewDate ?
                            <FormControl rounded="lg">
                                <HStack w="full" alignItems="end">
                                    <VStack alignItems="start" gap={2}>
                                        <Text
                                            fontWeight={500}
                                            fontSize="sm"
                                            color="gray.500"
                                        >
                                            It is at your discretion to decide when a review is deemed necessary. However, it is recommended that Risk Assessments are reviewed on an annual basis.
                                        </Text>
                                        <HStack>
                                            {
                                                !isMobile && <Button
                                                    size="sm"
                                                    onClick={() => {
                                                        const sixMonthsFromNow = dayjs(
                                                            dayjs().add(dayjs.duration({ 'months': 6 }))
                                                        )
                                                            .format("YYYY-MM-DD");

                                                        setDate(sixMonthsFromNow);
                                                        setEditBody({
                                                            ...editBody,
                                                            expirydate: sixMonthsFromNow
                                                        });
                                                    }}
                                                >
                                                    6 Months
                                                </Button>
                                            }

                                            <Button
                                                size="sm"
                                                onClick={() => {
                                                    const oneYearFromNow = dayjs(
                                                        dayjs().add(dayjs.duration({ 'years': 1 }))
                                                    )
                                                        .format("YYYY-MM-DD");

                                                    setDate(oneYearFromNow);
                                                    setEditBody({
                                                        ...editBody,
                                                        expirydate: oneYearFromNow
                                                    });
                                                }}
                                            >
                                                1 Year
                                            </Button>

                                            <Button
                                                size="sm"
                                                onClick={() => {
                                                    const twoYearsFromNow = dayjs(
                                                        dayjs().add(dayjs.duration({ 'years': 2 }))
                                                    )
                                                        .format("YYYY-MM-DD");

                                                    setDate(twoYearsFromNow);
                                                    setEditBody({
                                                        ...editBody,
                                                        expirydate: twoYearsFromNow
                                                    });
                                                }}
                                            >
                                                2 Years
                                            </Button>

                                            <Button
                                                size="sm"
                                                onClick={() => {
                                                    const threeYearsFromNow = dayjs(
                                                        dayjs().add(dayjs.duration({ 'years': 3 }))
                                                    )
                                                        .format("YYYY-MM-DD");

                                                    setDate(threeYearsFromNow);
                                                    setEditBody({
                                                        ...editBody,
                                                        expirydate: threeYearsFromNow
                                                    });
                                                }}
                                            >
                                                3 Years
                                            </Button>
                                        </HStack>
                                    </VStack>
                                </HStack>

                                <Input
                                    mt={2}
                                    type="date"
                                    value={date}
                                    onKeyDown={(e: any) => { e.preventDefault() }}
                                    onWheel={(e: any) => e.target.blur()}
                                    onChange={(e: any) => {
                                        setDate(e.target.value);
                                        setEditBody({
                                            ...editBody,
                                            expirydate: e.target.value
                                        });
                                    }}
                                />
                            </FormControl> :

                            <Box rounded="lg">
                                <HStack w="100%" alignItems="start" gap={1}>
                                    <Image src={hazardImg} h="85px" />

                                    <Spacer />

                                    <VStack gap={3} alignItems="start">
                                        <Box textAlign="left" w="100%">
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                There may be acknowledgments associated with it.
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                Are you sure you want to make changes?
                                            </Text>

                                            <Button
                                                mt={3}
                                                w="100%"
                                                size="sm"
                                                variant="outline"
                                                color="gray.600"
                                                fontWeight={600}
                                                onClick={() => {
                                                    setCanEditReviewDate(true);
                                                }}
                                            >
                                                <HStack>
                                                    <Icon as={BsPencil} />
                                                    <Text>Continue Editing</Text>
                                                </HStack>
                                            </Button>
                                        </Box>
                                    </VStack>
                                </HStack>
                            </Box>
                    }
                </ModalBody>

                {
                    canEditReviewDate && <ModalFooter>
                        <HStack>
                            <Button
                                cursor="pointer"
                                size="sm"
                                onClick={() => {
                                    setIsEditReviewDateModalOpen(false);
                                    setCanEditReviewDate(false);
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                colorScheme="brand"
                                size="sm"
                                _active={{ bg: "" }}
                                _hover={{ bg: "#248451" }}
                                onClick={() => {
                                    patchRiskAssessmentFromData("expirydate", date);
                                    setIsEditReviewDateModalOpen(false);
                                    setCanEditReviewDate(false);
                                }}
                            >
                                Update
                            </Button>
                        </HStack>
                    </ModalFooter>
                }
            </ModalContent>
        </Modal>
    };

    const renderEditTeamsModal = () => {
        return <Modal
            size="xl"
            isOpen={isEditTeamsModalOpen}
            onClose={() => {
                setIsEditTeamsModalOpen(false);
                setCanEditTeams(false);
            }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader fontSize={canEditTeams ? "xl" : "lg"}>
                    {canEditTeams ? "Teams to Acknowledge" : "This Risk Assessment has already been published"}
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody pb={6}>
                    {
                        canEditTeams ?
                            <FormControl rounded="lg">
                                <VStack alignItems="start">
                                    <Box>
                                        <Text
                                            fontWeight={500}
                                            fontSize="sm"
                                            color="gray.500"
                                        >
                                            The teams you select from the list are required to read and acknowledge this risk assessment.
                                        </Text>

                                        <Text
                                            mt={2}
                                            fontWeight={500}
                                            fontSize="sm"
                                            color="gray.500"
                                        >
                                            Teams you have responsibilities for appear in this list.
                                        </Text>
                                    </Box>

                                    <Box w="full">
                                        <TeamMessageCombobox
                                            teams={acknowledgementTeams}
                                            update={selectTeam}
                                        />
                                    </Box>
                                </VStack>

                                {
                                    selectedAcknowledgementTeams?.length > 0 && <Box textAlign="left">
                                        {
                                            selectedAcknowledgementTeams?.map((team: any, i: number) => (
                                                <Tag
                                                    key={`team_${i}`}
                                                    size="md"
                                                    mr={2}
                                                    mt={3}
                                                    borderRadius='lg'
                                                    variant='solid'
                                                    colorScheme='green'
                                                >
                                                    <TagLabel>{team.teamname}</TagLabel>
                                                    <TagCloseButton
                                                        onClick={() => {
                                                            deselectTeam(team.teamid);
                                                        }}
                                                    />
                                                </Tag>
                                            ))
                                        }
                                    </Box>
                                }
                            </FormControl> :

                            <Box rounded="lg">
                                <HStack w="100%" alignItems="start" gap={1}>
                                    <Image src={hazardImg} h="85px" />

                                    <Spacer />

                                    <VStack gap={3} alignItems="start">
                                        <Box textAlign="left" w="100%">
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                There may be acknowledgments associated with it.
                                            </Text>
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                            >
                                                Are you sure you want to make changes?
                                            </Text>

                                            <Button
                                                mt={3}
                                                w="100%"
                                                size="sm"
                                                variant="outline"
                                                color="gray.600"
                                                fontWeight={600}
                                                onClick={() => {
                                                    setCanEditTeams(true);
                                                }}
                                            >
                                                <HStack>
                                                    <Icon as={BsPencil} />
                                                    <Text>Continue Editing</Text>
                                                </HStack>
                                            </Button>
                                        </Box>
                                    </VStack>
                                </HStack>
                            </Box>
                    }
                </ModalBody>

                {
                    canEditTeams && <ModalFooter>
                        <HStack>
                            <Button
                                cursor="pointer"
                                size="sm"
                                onClick={() => {
                                    setIsEditTeamsModalOpen(false);
                                    setCanEditTeams(false);
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                colorScheme="brand"
                                size="sm"
                                _active={{ bg: "" }}
                                _hover={{ bg: "#248451" }}
                                onClick={() => {
                                    postRiskAssessmentTeams();
                                    setCanEditTeams(false);
                                    setIsEditTeamsModalOpen(false);
                                }}
                            >
                                Update
                            </Button>
                        </HStack>
                    </ModalFooter>
                }
            </ModalContent>
        </Modal>
    };

    const renderAcknowledgeModal = () => {
        return <Modal
            size="lg"
            isOpen={isAcknowledgeModalOpen}
            onClose={() => { setIsAcknowledgeModalOpen(false); }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader color="gray.700">
                    Acknowledge Risk Assessment
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody>
                    <Text
                        fontSize="sm"
                        fontWeight={500}
                        color="gray.600"
                    >
                        By clicking confirm you acknowledge that you read and fully understood this risk assessment.
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <HStack>
                        <Button onClick={() => { setIsAcknowledgeModalOpen(false); }} cursor="pointer" size="sm">
                            Cancel
                        </Button>

                        <Button
                            className="hover-pop"
                            colorScheme="brand"
                            size="sm"
                            _active={{ bg: "" }}
                            _hover={{ bg: "#248451" }}
                            onClick={acknowledge}
                        >
                            Confirm
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    };

    const renderPdf = () => {
        return <Modal
            size="4xl"
            isOpen={pdfIsOpen}
            onClose={() => {
                setPdfIsOpen(false);
                resetDocViewer();
            }}
        >
            <ModalOverlay />

            <ModalContent mt={0}>
                <ModalHeader
                    style={{
                        height: "50px",
                        borderRadius: "5px 5px 0 0",
                        background: "#38a169",
                        color: "white",
                    }}
                >
                    <HStack gap={1} paddingRight={10}>
                        <Text wordBreak="break-word" fontSize="lg" maxW="50%">{currentCert.fileName}</Text>
                        <Spacer />

                        <Button
                            bg="white"
                            color="brand.500"
                            float="left"
                            size="sm"
                            disabled={pageNumber === 1}
                            onClick={() => setPageNumber(pageNumber - 1)}
                        >
                            Back
                        </Button>
                        <Text fontSize="md">
                            {pageNumber} of {numPages}
                        </Text>
                        <Button
                            bg="white"
                            color="brand.500"
                            float="right"
                            size="sm"
                            disabled={pageNumber === numPages}
                            onClick={() => setPageNumber(pageNumber + 1)}
                        >
                            Next
                        </Button>

                        <ModalCloseButton
                            color="white"
                            _hover={{ bg: "" }}
                            _active={{ bg: "" }}
                        />
                    </HStack>
                </ModalHeader>

                <ModalBody>
                    <Document
                        file={currentCert.uri}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                            <Center my={48}>
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    color="brand.500"
                                    size="xl"
                                />
                            </Center>
                        }
                    >
                        <Page
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            pageNumber={pageNumber}
                        />
                    </Document>
                </ModalBody>
            </ModalContent>
        </Modal>
    };

    const renderImage = () => {
        return <Modal
            size="4xl"
            isOpen={isAttachmentOpen}
            onClose={() => { setIsAttachmentOpen(false) }}
        >
            <ModalOverlay />

            <ModalContent mt={0}>
                <ModalHeader />

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody>
                    <Image
                        src={currentImage}
                        m="5px auto 15px auto"
                        p={0}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    };

    return (
        <Box m={{ base: 0, md: 5 }}>

            {/* navigation */}
            {
                state?.from === "actions" ?
                    <Button
                        display={{ base: "none", md: "unset" }}
                        w="fit-content"
                        size="sm"
                        mb={2}
                        variant="outline"
                        color="gray.600"
                        fontWeight={600}
                        boxShadow="xs"
                        onClick={() => {
                            navigateTo(-1);
                        }}
                    >
                        <HStack>
                            <Icon as={BsArrowLeftCircle} />
                            <Text>Back to Action</Text>
                        </HStack>
                    </Button>
                    :
                    state?.from === "notification" ?
                        <Button
                            display={{ base: "none", md: "unset" }}
                            w="fit-content"
                            size="sm"
                            mb={2}
                            variant="outline"
                            color="gray.600"
                            fontWeight={600}
                            boxShadow="xs"
                            onClick={() => {
                                navigateTo(-1);
                            }}
                        >
                            <HStack>
                                <Icon as={BsArrowLeftCircle} />
                                <Text>Back to Notification</Text>
                            </HStack>
                        </Button>

                        :
                        <Button
                            display={{ base: "none", md: "unset" }}
                            w="fit-content"
                            size="sm"
                            mb={2}
                            variant="outline"
                            color="gray.600"
                            fontWeight={600}
                            boxShadow="xs"
                            onClick={() => {
                                navigateTo('/riskassessments');
                            }}
                        >
                            <HStack>
                                <Icon as={BsArrowLeftCircle} />
                                <Text>Back to Risk Assessments</Text>
                            </HStack>
                        </Button>
            }

            <Box
                h={{ base: "full", md: "unset" }}
                pt={5}
                pb={!isMobile && tabIndex !== 0 ? 3 : 0}
                px={{ base: 0, md: 5 }}
                bg="white"
                rounded={{ base: "none", md: "lg" }}
                roundedTop={!(isManager || canEdit) ? "lg" : "none"}
                boxShadow={{ base: "none", md: "lg" }}
            >
                {
                    isMobile &&
                    version?.ravstate === '1' &&
                    riskAssessment?.currentversion === null &&
                    hasRiskAssessmentEditingPermission &&
                    canEdit && <Button
                        mx={5}
                        mb={5}
                        mt={0}
                        float="right"
                        size="sm"
                        colorScheme="brand"
                        color="white"
                        _active={{ bg: "" }}
                        _hover={{ bg: "#248451" }}
                        onClick={createNewReview}
                    >
                        <HStack alignItems="center">
                            <Icon
                                as={BsPlusCircle}
                                color="white"
                            />
                            <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                Create New Review
                            </Text>
                        </HStack>
                    </Button>
                }

                {
                    isMobile &&
                    (version?.ravstate === '0' || version?.ravstate === '1') &&
                    riskAssessment?.currentversion != null &&
                    hasRiskAssessmentEditingPermission &&
                    canEdit && <Button
                        pr={5}
                        mb={5}
                        mt={0}
                        float="right"
                        size="sm"
                        colorScheme="brand"
                        color="white"
                        _active={{ bg: "" }}
                        _hover={{ bg: "#248451" }}
                        onClick={() => {
                            navigateTo(`/riskassessments/edit/${riskAssessment?.raid}`, {
                                state: {
                                    step: 1,
                                    isReview: true
                                }
                            });
                        }}
                    >
                        <HStack alignItems="center">
                            <Icon
                                as={BsPencil}
                                color="white"
                            />
                            <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                Continue  {version?.ravstate === '0' ? "Editing" : "Review"}
                            </Text>
                        </HStack>
                    </Button>
                }

                <Tabs
                    colorScheme="green"
                    onChange={(index) => setTabIndex(index)}
                    defaultIndex={0}
                    index={tabIndex}
                >
                    {
                        (isManager || canEdit) && <Flex
                            justify={{ base: "center", md: "space-between" }}
                            w={{ base: "full", md: "unset" }}
                            mx={{ base: 0, md: 0 }}
                            mb={{ base: 1, md: 2 }}
                        >
                            <TabList
                                ml={{ base: 0, md: 5 }}
                                w={{ base: "full", md: "unset" }}
                                fontSize={{ base: "xs", md: "md" }}
                                justifyContent="center"
                            >
                                <Tab fontSize={{ base: "xs", md: "sm" }}>
                                    Risk Assessment
                                </Tab>

                                {
                                    !isDraftVersion && canEdit && <Tab fontSize={{ base: "xs", md: "sm" }}>
                                        Acknowledgements
                                    </Tab>
                                }

                                {
                                    onEditingTeam && <Tab fontSize={{ base: "xs", md: "sm" }}>
                                        Version History
                                    </Tab>
                                }
                            </TabList>

                            {
                                publishedVersionId !== null &&
                                (version?.ravstate !== "0" && version?.ravstate !== "1") &&
                                <Stack
                                    mr={5}
                                    direction={{ base: 'column', sm: 'row' }}
                                    spacing={3}
                                    align={{ base: 'stretch', md: 'center' }}
                                >
                                    <Button
                                        size="sm"
                                        colorScheme="brand"
                                        color="white"
                                        _active={{ bg: "" }}
                                        _hover={{ bg: "#248451" }}
                                        onClick={() => {
                                            navigateTo(`/riskassessments/version/${publishedVersionId}`, {
                                                state: {
                                                    raid: riskAssessment?.raid,
                                                    ravid: publishedVersionId
                                                }
                                            });
                                        }}
                                    >
                                        <HStack alignItems="center">
                                            <Icon
                                                as={BsArrowLeftCircle}
                                                color="white"
                                            />
                                            <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                Back to Current Published Version
                                            </Text>
                                        </HStack>
                                    </Button>
                                </Stack>
                            }

                            {
                                !isMobile &&
                                (version?.ravstate === '0' || version?.ravstate === '1') &&
                                riskAssessment?.currentversion != null &&
                                hasRiskAssessmentEditingPermission &&
                                canEdit && <Stack
                                    mr={5}
                                    direction={{ base: 'column', sm: 'row' }}
                                    spacing={3}
                                    align={{ base: 'stretch', md: 'center' }}
                                >
                                    <Button
                                        size="sm"
                                        colorScheme="brand"
                                        color="white"
                                        _active={{ bg: "" }}
                                        _hover={{ bg: "#248451" }}
                                        onClick={() => {
                                            navigateTo(`/riskassessments/edit/${riskAssessment?.raid}`, {
                                                state: {
                                                    step: 1,
                                                    isReview: true
                                                }
                                            });
                                        }}
                                    >
                                        <HStack alignItems="center">
                                            <Icon
                                                as={BsPencil}
                                                color="white"
                                            />
                                            <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                Continue  {version?.ravstate === '0' ? "Editing" : "Review"}
                                            </Text>
                                        </HStack>
                                    </Button>
                                </Stack>
                            }

                            {
                                !isMobile &&
                                version?.ravstate === '1' &&
                                riskAssessment?.currentversion === null &&
                                hasRiskAssessmentEditingPermission &&
                                canEdit && <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    mr={5}
                                    spacing={3}
                                    align={{ base: 'stretch', md: 'center' }}
                                >
                                    <Button
                                        size="sm"
                                        colorScheme="brand"
                                        color="white"
                                        _active={{ bg: "" }}
                                        _hover={{ bg: "#248451" }}
                                        onClick={createNewReview}
                                    >
                                        <HStack alignItems="center">
                                            <Icon
                                                as={BsPlusCircle}
                                                color="white"
                                            />
                                            <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                                                Create New Review
                                            </Text>
                                        </HStack>
                                    </Button>
                                </Stack>
                            }
                        </Flex>
                    }

                    <TabPanels>
                        <TabPanel
                            p={0}
                            pt={(isManager || canEdit) ? 3 : 0}
                            pb={{
                                base: 0,
                                md: riskAssessment?.acknowledgementrequired ||
                                    acknowledgement?.raadateacknowledged != null ? 0 : 5
                            }}
                        >
                            <VStack alignItems="start" gap={1}>
                                <Box w="full">
                                    {
                                        riskAssessment?.acknowledgementrequired &&
                                        version.ravid === riskAssessment?.rapublishedversion && <Box
                                            mb={5}
                                            mx={isMobile ? 3 : 0}
                                            p={3}
                                            border="1px solid"
                                            borderColor="gray.200"
                                            rounded="lg"
                                            backgroundColor='gray.100'
                                        >
                                            <HStack>
                                                {!isMobile && <Icon as={BsExclamationCircle} />}
                                                <Text
                                                    fontSize="md"
                                                    fontWeight={500}
                                                    color="gray.600"
                                                >
                                                    Please review this document carefully and acknowledge that you have understood it at the end of the document.
                                                </Text>
                                            </HStack>
                                        </Box>
                                    }

                                    <Grid
                                        templateColumns={{ base: "repeat(1, 4fr)", md: "repeat(, 3fr)", lg: "repeat(4, 3fr)" }}
                                        gap={6}
                                        p={5}
                                        mx={{ base: 0, md: -5 }}
                                        bgGradient='linear(to-r, green.500, green.400)'
                                        roundedTop={!(isManager || canEdit) ? "lg" : "none"}
                                    >
                                        <GridItem colSpan={4}>
                                            <VStack w="full" alignItems="baseline">
                                                <Stack w="full" gap={1} direction={isMobile ? "column" : "row"}>
                                                    {
                                                        !isMobile && <>
                                                            <Image src={miniLogoWhite} h={{ base: "70px", md: "100px" }} />

                                                            <Divider
                                                                orientation="vertical"
                                                                color="white"
                                                                h="100px"
                                                                ml={8}
                                                            />
                                                        </>
                                                    }

                                                    {
                                                        <Box pr={3} alignContent="center">
                                                            <SlideFade in={!firstTimeLoading} style={{ width: "100%" }}>
                                                                <HStack
                                                                    cursor="pointer"
                                                                    onClick={() => {
                                                                        setIsEditNameModalOpen(true);
                                                                    }}
                                                                >
                                                                    <Text
                                                                        fontSize={{ base: "3xl", lg: "30pt" }}
                                                                        fontWeight={600}
                                                                        color="white"
                                                                        mt={isMobile ? 0 : -3}
                                                                    >
                                                                        {riskAssessment?.raname}
                                                                    </Text>

                                                                    {
                                                                        canEdit && <Button
                                                                            size="xs"
                                                                            variant="ghost"
                                                                            alignSelf="baseline"
                                                                            color="whiteAlpha.800"
                                                                            _hover={{ bg: "transparent", color: "white" }}
                                                                            _active={{ bg: "transparent" }}
                                                                        >
                                                                            <Icon as={BsPencil} />
                                                                        </Button>
                                                                    }
                                                                </HStack>
                                                            </SlideFade>

                                                            <HStack color="whiteAlpha.700">
                                                                <Text
                                                                    fontSize={{ base: "md", lg: "lg" }}
                                                                    fontWeight={600}
                                                                    ml="2px"
                                                                >
                                                                    RISK ASSESSMENT
                                                                </Text>

                                                                {
                                                                    version?.ravstate === '0' && <Badge
                                                                        variant="outline"
                                                                        color="white"
                                                                        colorScheme="whiteAlpha"
                                                                    >
                                                                        <Icon
                                                                            as={BsPencil}
                                                                            mt="-2px"
                                                                            mr="2px"
                                                                            verticalAlign="middle"
                                                                        /> Draft</Badge>
                                                                }
                                                                {
                                                                    version?.ravstate === '1' && <Badge
                                                                        variant="outline"
                                                                        color="white"
                                                                        colorScheme="whiteAlpha"
                                                                    >
                                                                        <Icon
                                                                            as={BsCheckCircle}
                                                                            mt="-2px"
                                                                            mr="2px"
                                                                            verticalAlign="middle"
                                                                        /> Published</Badge>
                                                                }
                                                                {
                                                                    version?.ravstate === '2' && <Badge
                                                                        variant="outline"
                                                                        color="white"
                                                                        colorScheme="whiteAlpha"
                                                                    >
                                                                        <Icon
                                                                            as={BsArchive}
                                                                            mt="-2px"
                                                                            mr="2px"
                                                                            verticalAlign="middle"
                                                                        /> Archived</Badge>
                                                                }
                                                                {
                                                                    version?.ravstate === '99' && <Badge
                                                                        variant="outline"
                                                                        color="white"
                                                                        colorScheme="whiteAlpha"
                                                                    >
                                                                        <Icon
                                                                            as={BsTrash}
                                                                            mt="-2px"
                                                                            mr="2px"
                                                                            verticalAlign="middle"
                                                                        /> Deleted</Badge>
                                                                }
                                                            </HStack>
                                                        </Box>
                                                    }

                                                    {
                                                        isMobile && acknowledgement?.raadateacknowledged != null && <HStack color="white">
                                                            <Icon as={BsCheckCircle} />
                                                            <Text
                                                                fontSize="sm"
                                                                fontWeight={500}
                                                            >
                                                                Acknowledged {localDate(acknowledgement?.raadateacknowledged)}
                                                            </Text>
                                                        </HStack>
                                                    }

                                                    {
                                                        !isMobile && <>
                                                            <Spacer />

                                                            <VStack textAlign="right" minW="150px">
                                                                <SlideFade in={!firstTimeLoading} style={{ width: "100%" }}>
                                                                    <Text
                                                                        fontSize="sm"
                                                                        fontWeight={500}
                                                                        color="white"
                                                                        mt={1}
                                                                    >
                                                                        Version {version?.ravsequencenumber}
                                                                    </Text>

                                                                    <Text
                                                                        fontSize="sm"
                                                                        fontWeight={500}
                                                                        color="white"
                                                                        mt={1}
                                                                    >
                                                                        {
                                                                            !isNull(riskAssessment?.publishedversion?.ravdatecreated) && version?.ravstate !== "0" ?
                                                                                `Published ${localDate(riskAssessment?.publishedversion?.ravdatecreated)}` :
                                                                                `Created ${localDate(riskAssessment?.currentversion?.ravdatecreated)}`
                                                                        }
                                                                    </Text>
                                                                </SlideFade>

                                                                {
                                                                    acknowledgement?.raadateacknowledged != null && <HStack color="white">
                                                                        <Icon as={BsCheckCircle} />
                                                                        <Text
                                                                            fontSize="sm"
                                                                            fontWeight={500}
                                                                        >
                                                                            Acknowledged {localDate(acknowledgement?.raadateacknowledged)}
                                                                        </Text>
                                                                    </HStack>
                                                                }
                                                            </VStack>
                                                        </>
                                                    }
                                                </Stack>
                                            </VStack>
                                        </GridItem>

                                        <GridItem colSpan={{ base: 4, md: 1 }}>
                                            <VStack alignItems="start">
                                                <HStack
                                                    cursor={canEdit ? "pointer" : "default"}
                                                    onClick={() => {
                                                        if (canEdit) setIsEditManagingTeamModalOpen(true);
                                                    }}
                                                >
                                                    <Text
                                                        fontWeight={500}
                                                        color="whiteAlpha.800"
                                                    >
                                                        Managing Team
                                                    </Text>
                                                    {
                                                        canEdit &&
                                                        <Button
                                                            size="xs"
                                                            variant="ghost"
                                                            color="whiteAlpha.800"
                                                            _hover={{ bg: "transparent", color: "white" }}
                                                            _active={{ bg: "transparent" }}
                                                        >
                                                            <Icon as={BsPencil} />
                                                        </Button>
                                                    }
                                                </HStack>

                                                <SkeletonText isLoaded={!loading} noOfLines={1} w="75%">
                                                    <Text
                                                        fontWeight={500}
                                                        fontSize="11pt"
                                                        color="white"
                                                    >
                                                        {riskAssessment?.editingteamname}
                                                    </Text>
                                                </SkeletonText>
                                            </VStack>
                                        </GridItem>

                                        <GridItem colSpan={{ base: 4, md: 1 }}>
                                            <VStack alignItems="start">
                                                <HStack
                                                    cursor={canEdit ? "pointer" : "default"}
                                                    onClick={() => {
                                                        if (canEdit) setIsEditCategoryModalOpen(true);
                                                    }}
                                                >
                                                    <Icon as={BsExclamationCircle} color="whiteAlpha.800" />
                                                    <Text
                                                        fontWeight={500}
                                                        color="whiteAlpha.800"
                                                    >
                                                        Category
                                                    </Text>
                                                    {
                                                        canEdit &&
                                                        <Button
                                                            size="xs"
                                                            variant="ghost"
                                                            color="whiteAlpha.800"
                                                            _hover={{ bg: "transparent", color: "white" }}
                                                            _active={{ bg: "transparent" }}
                                                        >
                                                            <Icon as={BsPencil} />
                                                        </Button>
                                                    }
                                                </HStack>

                                                <SkeletonText isLoaded={!loading} noOfLines={1} w="75%">
                                                    <Text
                                                        fontWeight={500}
                                                        fontSize="11pt"
                                                        color="white"
                                                    >
                                                        {riskAssessment?.racategory || "-"}
                                                    </Text>
                                                </SkeletonText>
                                            </VStack>
                                        </GridItem>

                                        <GridItem colSpan={{ base: 4, md: 1 }}>
                                            <VStack alignItems="start">
                                                <HStack
                                                    cursor={canEdit ? "pointer" : "default"}
                                                    onClick={() => {
                                                        if (canEdit) setIsEditReviewDateModalOpen(true);
                                                    }}
                                                >
                                                    <Icon as={BsCalendar2Week} color="whiteAlpha.800" />
                                                    <Text
                                                        fontWeight={500}
                                                        color="whiteAlpha.800"
                                                    >
                                                        Next Review Date
                                                    </Text>
                                                    {
                                                        canEdit &&
                                                        <Button
                                                            size="xs"
                                                            variant="ghost"
                                                            color="whiteAlpha.800"
                                                            _hover={{ bg: "transparent", color: "white" }}
                                                            _active={{ bg: "transparent" }}
                                                        >
                                                            <Icon as={BsPencil} />
                                                        </Button>
                                                    }
                                                </HStack>

                                                <SkeletonText isLoaded={!loading} noOfLines={1} w="75%">
                                                    <Text
                                                        fontWeight={500}
                                                        fontSize="11pt"
                                                        color="white"
                                                    >
                                                        {localDate(riskAssessment?.raexpirydate) || "-"}
                                                    </Text>
                                                </SkeletonText>
                                            </VStack>
                                        </GridItem>

                                        <GridItem
                                            w="full"
                                            rowSpan={2}
                                            colSpan={{ base: 4, md: 1 }}
                                        >
                                            <VStack alignItems="start" w="full">
                                                <HStack
                                                    cursor={canEdit ? "pointer" : "default"}
                                                    onClick={() => {
                                                        if (canEdit) setIsEditTeamsModalOpen(true);
                                                    }}
                                                >
                                                    <Icon as={BsPeople} color="whiteAlpha.800" />
                                                    <Text
                                                        fontWeight={500}
                                                        color="whiteAlpha.800"
                                                    >
                                                        Teams to Acknowledge
                                                    </Text>
                                                    {
                                                        canEdit &&
                                                        <Button
                                                            size="xs"
                                                            variant="ghost"
                                                            color="whiteAlpha.800"
                                                            _hover={{ bg: "transparent", color: "white" }}
                                                            _active={{ bg: "transparent" }}
                                                        >
                                                            <Icon as={BsPencil} />
                                                        </Button>
                                                    }
                                                </HStack>

                                                <SlideFade in={!firstTimeLoading} style={{ width: "100%" }}>
                                                    {
                                                        riskAssessment?.teams?.length > 0 && <VStack
                                                            alignItems="start"
                                                            bg="whiteAlpha.900"
                                                            p={2}
                                                            w="100%"
                                                            rounded="lg"
                                                        >
                                                            {
                                                                riskAssessment?.teams?.map((t: any, index: any) => {
                                                                    return <HStack key={`ack_${index}`} w="full">
                                                                        <Avatar
                                                                            name={t.teamname}
                                                                            size="xs"
                                                                            color="white"
                                                                        />
                                                                        <Text
                                                                            fontWeight={500}
                                                                            fontSize="10pt"
                                                                            color="gray.600"
                                                                        >
                                                                            {t.teamname}
                                                                        </Text>
                                                                    </HStack>
                                                                })
                                                            }
                                                        </VStack>
                                                    }
                                                </SlideFade>
                                            </VStack>
                                        </GridItem>

                                        <GridItem colSpan={2}>
                                            <VStack alignItems="start">
                                                <HStack
                                                    cursor={canEdit ? "pointer" : "default"}
                                                    onClick={() => {
                                                        if (canEdit) setIsEditDescriptionModalOpen(true);
                                                    }}
                                                >
                                                    <Text
                                                        fontWeight={500}
                                                        color="whiteAlpha.800"
                                                    >
                                                        Description
                                                    </Text>
                                                    {
                                                        canEdit &&
                                                        <Button
                                                            size="xs"
                                                            variant="ghost"
                                                            color="whiteAlpha.800"
                                                            _hover={{ bg: "transparent", color: "white" }}
                                                            _active={{ bg: "transparent" }}
                                                        >
                                                            <Icon as={BsPencil} />
                                                        </Button>
                                                    }
                                                </HStack>

                                                <SkeletonText isLoaded={!loading} noOfLines={4} w="100%">
                                                    <Text
                                                        fontWeight={500}
                                                        fontSize="11pt"
                                                        color="white"
                                                    >
                                                        {riskAssessment?.radescription || "N/A"}
                                                    </Text>
                                                </SkeletonText>
                                            </VStack>
                                        </GridItem>
                                    </Grid>

                                    {
                                        attachments.length > 0 && <VStack
                                            alignItems="start"
                                            m={isMobile ? 3 : 0}
                                            mt={isMobile ? 3 : 5}
                                            mb={5}
                                        >
                                            <FormControl>
                                                <FormLabel
                                                    fontWeight={{ base: 500, lg: 600 }}
                                                    fontSize={{ base: "md", md: "lg" }}
                                                    textAlign="left"
                                                    color="gray.700"
                                                >
                                                    <HStack>
                                                        <Icon as={BsPaperclip} fontSize="md" />
                                                        <Text fontWeight={600}>Attachments</Text>
                                                    </HStack>
                                                </FormLabel>

                                                <SlideFade in={!loading} offsetY="20px">
                                                    <Box
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                        flexWrap={'wrap'}
                                                        justifyContent={'flex-start'}
                                                        alignItems={'center'}
                                                        gap={3}
                                                    >
                                                        {
                                                            attachments.map((a: any, index: any) => {
                                                                return (
                                                                    <Box key={index}>
                                                                        <Image
                                                                            className="hover-pop"
                                                                            cursor="pointer"
                                                                            src={a.thumbnail}
                                                                            fallbackSrc='https://via.placeholder.com/160'
                                                                            alt={a.fileName}
                                                                            boxSize='160px'
                                                                            boxShadow="xs"
                                                                            objectFit="cover"
                                                                            border="1px solid"
                                                                            borderColor="gray.200"
                                                                            rounded="lg"
                                                                            onClick={() => {

                                                                                console.log(a);
                                                                                fetchAttachment(riskAssessment?.raid, version?.ravid, a.path, a.isPdf, a.isDoc)
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                </SlideFade>
                                            </FormControl>
                                        </VStack>
                                    }

                                    <HStack
                                        w="full"
                                        pt={attachments.length > 0 ? 0 : 5}
                                        pb={3}
                                        px={{ base: 3, md: 0 }}
                                    >
                                        <VStack alignItems="baseline">
                                            <Text
                                                fontWeight={{ base: 600, lg: 700 }}
                                                fontSize="2xl"
                                                textAlign="left"
                                                color="gray.700"
                                            >
                                                Hazards
                                            </Text>

                                            <Text color="gray.600" fontSize={{ base: "xs", md: "sm" }}>
                                                Please read the list of hazards linked to this assessment. Understanding these risks will help keep you and your colleagues safe.
                                            </Text>
                                        </VStack>

                                        <Spacer />

                                        <Image
                                            src={hazardImg}
                                            h={{ base: "75px", md: "100px" }}
                                            pt={2}
                                        />
                                    </HStack>

                                    <Box
                                        w="full"
                                        pt={3}
                                        bg="whiteAlpha.400"
                                        roundedBottom="lg"
                                    >
                                        {
                                            isEmpty(version?.hazards) ? <Center my={5} w="full">
                                                <VStack gap={2}>
                                                    <Box textAlign="center">
                                                        <Text
                                                            fontSize="lg"
                                                            fontWeight={700}
                                                            mb={2}
                                                        >
                                                            No Hazards
                                                        </Text>

                                                        <Text
                                                            fontSize="sm"
                                                            fontWeight={400}
                                                            color="gray.500"
                                                        >
                                                            No Hazards have been added to this Risk Assessment
                                                        </Text>
                                                    </Box>
                                                </VStack>
                                            </Center> :

                                                <VStack gap={{ base: 0, md: 2 }} pt={2}>
                                                    {
                                                        version?.hazards.map((h: any) => {
                                                            return <SlideFade in={!firstTimeLoading} style={{ width: "100%" }}>
                                                                <HazardReader
                                                                    key={h.rahid}
                                                                    riskAssessmentId={riskAssessment?.raid}
                                                                    riskAssessmentVersionId={version?.ravid}
                                                                    hazard={h}
                                                                    publishing={true}
                                                                />
                                                            </SlideFade>
                                                        })
                                                    }
                                                </VStack>
                                        }
                                    </Box>
                                </Box>
                            </VStack>

                            {
                                riskAssessment?.acknowledgementrequired &&
                                version?.ravid === riskAssessment?.rapublishedversion && <Box
                                    bgGradient={firstTimeLoading ? "white" : 'linear(to-r, green.500, green.400)'}
                                    roundedBottom={{ base: "none", md: "lg" }}
                                    mx={{ base: 0, md: -5 }}
                                    px={{ base: 3, md: 5 }}
                                    pb={{ base: 8, md: 4 }}
                                    pt={3}
                                    mt={4}
                                >
                                    <VStack alignItems="start" gap={1}>
                                        <Text
                                            fontSize={{ base: "lg", lg: "lg" }}
                                            fontWeight={600}
                                            color="white"
                                        >
                                            Review and Acknowledge
                                        </Text>

                                        <Text
                                            fontSize={{ base: "xs", lg: "sm" }}
                                            fontWeight={500}
                                            color="white"
                                            w={{ base: "100%", md: "75%" }}
                                        >
                                            {user.forename}, please review the information above carefully. By clicking the button below, you acknowledge that you have read and understood this risk assessment.
                                        </Text>

                                        <Button
                                            w={{ base: "100%", md: "unset" }}
                                            size={isMobile ? "md" : "sm"}
                                            colorScheme="white"
                                            color="green.500"
                                            _hover={{ bg: "" }}
                                            _active={{ bg: "" }}
                                            onClick={() => { setIsAcknowledgeModalOpen(true); }}
                                        >
                                            <HStack>
                                                <Icon as={BsHandThumbsUp} />
                                                <Text>Confirm</Text>
                                            </HStack>
                                        </Button>
                                    </VStack>
                                </Box>
                            }
                        </TabPanel>

                        {
                            (!isDraftVersion && canEdit) && <TabPanel>
                                <Acknowledgements
                                    version={version}
                                    riskAssessment={riskAssessment} />
                            </TabPanel>
                        }

                        {
                            onEditingTeam && <TabPanel>
                                <Versions
                                    version={version}
                                    riskAssessment={riskAssessment} />
                            </TabPanel>
                        }
                    </TabPanels>
                </Tabs>
            </Box>

            {renderAcknowledgeModal()}

            {renderEditManagingTeamModal()}
            {renderEditNameModal()}
            {renderEditDescriptionModal()}
            {renderEditCategoryModal()}
            {renderEditReviewDateModal()}
            {renderEditTeamsModal()}

            {renderPdf()}
            {renderImage()}
        </Box >
    );
};